import React, { useState, useEffect, useContext } from "react";

import SiteWrapper from "../../Layouts/SiteWrapper";
import { Button } from "react-bootstrap";
import { Grid } from "tabler-react";
import { Link } from "react-router-dom";

import Loader from "react-loader-spinner";

import PaginationCard from "../../Components/Artis/PaginationCardComponent";
import PaginationSelectedCard from "../../Components/ArtistSp/PaginationSelectedCardComponent";
import { Context } from "../../Context/Store";
import { ContextPermission } from "../../Context/Permission";
import { ContextAlert } from "../../Context/Alert";
import {
  GetTalentProspect,
  GetTalentProvinces,
} from "../../actions/actions-kandidat-artis";
import { GetArtisSp } from "../../actions/actions-artis-sp";
import { ArtistSpCard } from "../../Components/ArtistSp/ArtistSpCard";
import TambahArtistSp from "../../Components/ArtistSp/TambahArtistSp";
import FilterCard from "../../Components/ArtistSp/FilterCardComponent";

export const ArtistSp = () => {
  const breadcrumbItems = [
    {
      value: "Artist SP",
      to: "/artist-sp",
      active: false,
    },
  ];

  const token = localStorage.getItem("token");
  const [dataValue, setDataValue] = useState([]);
  const [talentProsepect, setTalentProspect] = useState([]);
  const [state, dispatch] = useContext(Context);
  const [dataProvinsi, setDataProvinsi] = useState([]);
  const statePermission = useContext(ContextPermission);
  const [message, setMessage] = useContext(ContextAlert);
  const [loader, setLoader] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 12,
    prev_page_url: "",
    next_page_url: "",
    from: "",
    to: "",
    last_page: "",
    total: "",
    tampil: "",
  });

  useEffect(() => {
    if (loader === true) {
      GetTalentProspect(token, ({ data, isFound, isError }) => {
        if (isFound) {
          setTalentProspect(data.data);
        } else if (isError) {
          handleError(data);
        }
      });
      GetTalentProvinces(token, ({ data, isFound, isError }) => {
        if (isFound) {
          setDataProvinsi(
            data &&
              data.map((item, index) => {
                return {
                  value: item.id,
                  label: item.name,
                };
              })
          );
        } else if (isError) {
          handleError(data);
        }
      });
    }
  }, [loader]);

  useEffect(() => {
    dispatch({ ...state, submit: true });
  }, []);

  useEffect(() => {
    fetchData();
  }, [pagination.page]);

  useEffect(() => {
    if (state.submit === true && pagination.page !== 1) {
      setPagination({ ...pagination, page: 1 });
    } else if (state.submit === true) {
      fetchData();
    }
  }, [state.submit]);

  function fetchData() {
    if (isLoading) return;
    GetArtisSp(
      `page=${pagination.page}&per_page=${pagination.per_page}`,
      state,
      token,
      ({ data, isFetching, isFound, isError }) => {
        setLoading(isFetching);
        if (isFound) {
          setDataValue(data.data.data.data);
          setPagination({
            ...pagination,
            prev_page_url: data.data.prev_page_url,
            next_page_url: data.data.next_page_url,
            from: data.data.from,
            to: data.data.to,
            last_page: data.data.last_page,
            total: data.data.total,
            tampil: data.data.data.data.length,
          });
          dispatch({ ...state, submit: false });
          setLoader(false);
        } else if (isError) {
          handleError(isError);
        }
      }
    );
  }

  function handleError(e) {
    let message = e.response?.data?.message;
    if (!message) {
      message = e.message;
    }
    setMessage({
      type: "danger",
      message: JSON.stringify(message),
      show: "d-block",
    });
  }

  const handleShow = () => {
    setShow(true);
  };

  const rightBreadcrumb = (
    <Button
      variant="danger"
      className="w-100"
      type="button"
      onClick={handleShow}
    >
      <i className="bx bx-plus"></i> Tambah Artist SP
    </Button>
  );

  return (
    <>
      <TambahArtistSp
        show={show}
        setShow={(e) => {
          setShow(e);
          fetchData();
        }}
      />
      <SiteWrapper
        breadcrumbItems={breadcrumbItems}
        title={"Kandidat Artis"}
        rightBreadcrumb={
          statePermission.permission.artis_sp.tambah_artis_sp.active === 1
            ? rightBreadcrumb
            : ""
        }
      >
        <Loader
          visible={loader}
          className="loaders"
          type="ThreeDots"
          color="#ED1C24"
          height="100"
          width="100"
        />
        {statePermission.home_menu.kandidat.active === 1 ? (
          <Grid.Row>
            <Grid.Col width="3">
              <FilterCard
                item={talentProsepect}
                filter={state}
                setFilter={dispatch}
              ></FilterCard>
            </Grid.Col>

            <Grid.Col width="9">
              <PaginationSelectedCard
                item={pagination}
                filter={state}
                setFilter={dispatch}
                talentProsepect={talentProsepect}
              ></PaginationSelectedCard>
              <Grid.Row>
                {dataValue &&
                  dataValue.map((item, index) => {
                    return (
                      <Grid.Col width="3" className="px-1">
                        <ArtistSpCard
                          dataProvinsi={dataProvinsi}
                          button={true}
                          item={item}
                          fetch={() => {
                            fetchData();
                          }}
                        ></ArtistSpCard>
                      </Grid.Col>
                    );
                  })}
              </Grid.Row>

              <PaginationCard
                item={pagination}
                setPagination={setPagination}
              ></PaginationCard>
            </Grid.Col>
          </Grid.Row>
        ) : (
          <Grid.Row>
            <Grid.Col width="12" className="text-center">
              Anda Tidak Memiliki Akses Artist SP
            </Grid.Col>
          </Grid.Row>
        )}
      </SiteWrapper>
    </>
  );
};
