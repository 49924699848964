import React, { useState, useEffect, useContext } from "react";
import { Modal, Button, Dropdown, Row, Col, Image } from "react-bootstrap";
import { Grid, FormTextInput, Form } from "tabler-react";
import { ContextAlert } from "../../Context/Alert";
import { Link } from "react-router-dom";
import {
  GetArtisBiografi,
  UpdateArtisBiografi,
  apiValidationData,
  getDataNpwp,
  getDataSupplierByNik,
} from "../../actions/actions-artis";
import "./kandidat.css";
import axios from "axios";

function JadikanArtisSp(prop) {
  const [urlImageAvatar] = useState(
    process.env.REACT_APP_FRONTEND_URL + "images/settings.png"
  );
  const token = localStorage.getItem("token");
  const [show, setShow] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const handleShow = () => {
    setShow(true);
    setLoader(true);
  };
  const handleClose = () => {
    setInput({
      status_pernikahan: "",
      alamat: "",
      telepon_1: "",
      nama_ktp: "",
      id_ktp: "",
      email_bp: "",
      kewarganegaraan: "",
      pekerjaan: "",
      kecamatan: "",
      kel_desa: "",
      rw: 0,
      rt: 0,
      gol_darah: "",
      jenis_kelamin: "",
      tempat_lahir: "",
      agama: "",
      tgl_lahir: "",
      status_pernikhan: "",
      erp_id: 0,
      id_dbtalent: 0,
      email: "",
    });
    setShow(false);
  };
  const [disabled, setDisabled] = useState(true);
  const [loader, setLoader] = useState(false);
  const [message, setMessage] = useContext(ContextAlert);
  const [resWarning, setResWarning] = useState("");

  const [inputNpwp, setInputNpwp] = useState([
    { nama_npwp: "", npwp: "", alamat_npwp: "" },
  ]);
  const [input, setInput] = useState({
    id: 0,
    status_pernikahan: "",
    alamat: "",
    telepon_1: "",
    nama_ktp: "",
    id_ktp: "",
    email_bp: "",
    kewarganegaraan: "",
    pekerjaan: "",
    kecamatan: "",
    kel_desa: "",
    rw: 0,
    rt: 0,
    gol_darah: "",
    jenis_kelamin: "",
    tempat_lahir: "",
    agama: "",
    tgl_lahir: "",
    status_pernikhan: "",
    erp_id: 0,
    id_dbtalent: 0,
    email: "",
  });

  const handleAddNpwpFields = () => {
    const values = [...inputNpwp];
    values.push({ nama_npwp: "", npwp: "", alamat_npwp: "" });
    setInputNpwp(values);
  };

  const handleRemoveNpwpFields = (index) => {
    const values = [...inputNpwp];
    values.splice(index, 1);
    setInputNpwp(values);
  };

  const handleInputNpwpChange = (index, event) => {
    const values = [...inputNpwp];
    if (event.target.name === "nama_npwp") {
      values[index].nama_npwp = event.target.value;
    } else if (event.target.name === "alamat_npwp") {
      values[index].alamat_npwp = event.target.value;
    } else {
      const re = /^[0-9\b]+$/;
      if (event.target.value === "" || re.test(event.target.value)) {
        values[index].npwp = event.target.value;
      }
    }
    setInputNpwp(values);
  };

  function handleChange(evt, name) {
    let value = "";
    if (name) {
      var label = "provinsi_nama";
      if (name === "kota_id") {
        label = "kota_nama";
      }
      setInput({
        ...input,
        [name]: evt.value,
        [label]: evt.label,
      });
    } else {
      let name = evt.target.name;
      value = evt.target.value;
      const re = /^[0-9\b]+$/;
      let newName = [
        "tinggi",
        "berat",
        "nilai_casting",
        "fax",
        "telepon_1",
        "telepon_2",
        "hp_1",
        "hp_2",
        "telepon_manajer",
        "telepon_manajemen_1",
        "telepon_manajemen_2",
        "telepon_agency_1",
        "telepon_agency_2",
      ];

      if (newName.includes(name)) {
        if (value === "" || re.test(value)) {
          setInput({
            ...input,
            [evt.target.name]: value,
          });
        }
      } else {
        if (name === "foto") {
          value = evt.target.files[0];
          setImage(URL.createObjectURL(value));
        }
        setInput({
          ...input,
          [evt.target.name]: value,
        });
      }
    }
  }

  useEffect(() => {
    // const mandatoryInput =
    //   inputNpwp.every(
    //     (item) => item.nama_npwp && item.npwp && item.alamat_npwp
    //   ) && Object.values(input).every((x) => x !== null && x !== "");

    const mandatoryInput =
      // inputNpwp.every(
      //   (item) => item.nama_npwp && item.npwp && item.alamat_npwp
      // ) &&
      // input.status_pernikahan &&
      // input.alamat &&
      input.nama_ktp && input.id_ktp && input.email_bp;

    if (mandatoryInput) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [inputNpwp, input]);

  function handleError(e) {
    let message = e;
    if (!message) {
      message = e.message;
    }
    setMessage({
      type: "danger",
      message: JSON.stringify(message),
      show: "d-block",
    });
  }

  useEffect(() => {
    if (show) {
      GetArtisBiografi(
        `id=${prop.data.id}`,
        token,
        ({ data, isFetching, isFound, isError }) => {
          setLoader(isFetching);
          if (isFound) {
            if (data.data) {
              setInput({
                id: data.data.id_dbtalent,
                status_pernikahan: data.data.status_perkawinan,
                alamat: data.data.alamat,
                telepon_1: data.data.telepon_dbtalent,
                nama_ktp: data.data.supplier_name,
                id_ktp: data.data.id_ktp,
                email_bp: data.data.email_bp,
                agama: data.data.agama,
                tgl_lahir: data.data.tanggal_lahir,
                tempat_lahir: data.data.tempat_lahir,
                jenis_kelamin:
                  data.data.jenis_kelamin == "L"
                    ? "Laki-laki"
                    : data.data.jenis_kelamin == "P"
                    ? "Perempuan"
                    : "",
                provinsi: data.data.provinsi,
                kel_desa: data.data.kelurahan,
                kecamatan: data.data.kecamatan,
                rt_rw: data.data.rt_rw,
                pekerjaan: data.data.pekerjaan,
                kewarganegaraan: data.data.kewarganegaraan,
                erp_id: data.data.erp_id,
                id_dbtalent: data.data.id_dbtalent,
                email: data.data.email,
              });
              if (
                Array.isArray(data.data.data_npwp) &&
                data.data.data_npwp.length
              ) {
                // setInputNpwp(data.data.taxes);
                let datass = [];
                data.data.data_npwp.forEach((element) => {
                  if (element.nama) {
                    datass.push({
                      nama_npwp: element.nama,
                      npwp: element.npwp,
                      alamat_npwp: element.alamat,
                    });
                  }
                });
                setInputNpwp(datass);
              }
            }
          }
          if (isError) {
            handleError(data);
          }
        }
      );
    } else {
      setInputNpwp([
        {
          nama_npwp: "",
          npwp: "",
          alamat_npwp: "",
        },
      ]);
    }
  }, [show]);

  async function submit() {
    const dataResult = {
      no_ajuan_kontrak:
        (input.erp_id != null ? input.erp_id : 0) +
        "|" +
        (Number.isInteger(input.id_dbtalent)
          ? input.id_dbtalent.toString()
          : input.id_dbtalent) +
        "|" +
        input.id_ktp,
      jenis_proses: 3,
    };

    apiValidationData(dataResult, token, ({ data, isFound, isError }) => {
      if (data) {
        if (Array.isArray(data.data) && data.data.length) {
          setShow(false);
          setShowWarning(true);
          setResWarning(data.data[0].warning);
        } else {
          submit2();
        }
      }
    });
  }

  function submit2() {
    const dataResult = {
      no_ajuan_kontrak:
        (input.erp_id != null ? input.erp_id : 0) +
        "|" +
        (Number.isInteger(input.id_dbtalent)
          ? input.id_dbtalent.toString()
          : input.id_dbtalent) +
        "|" +
        input.id_ktp,
      jenis_proses: 4,
    };

    apiValidationData(dataResult, token, ({ data, isFound, isError }) => {
      if (data) {
        if (Array.isArray(data.data) && data.data[0]?.warning?.trim() !== "") {
          handleError(data.data[0].warning.trim());
        } else {
          setDisabled(true);
          let npwpData = inputNpwp.map((val) => {
            return {
              artist_id: prop.data.id,
              nama_npwp: val.nama_npwp,
              npwp: val.npwp,
              alamat_npwp: val.alamat_npwp,
            };
          });
          const body = {
            ...input,
            tax: npwpData,
          };
          UpdateArtisBiografi(
            { body, token, params: `id=${prop.data.id}` },
            ({ data, isFound, isError }) => {
              if (data) {
                if (isFound && data.status !== "error") {
                  handleClose();
                  setShow(false);
                  setShowWarning(false);
                  setDisabled(false);
                  handleCreateArtis();
                } else if (isError || (isFound && data.status === "error")) {
                  handleError(data?.message);
                  setDisabled(false);
                }
              }
            }
          );
        }
      }
    });
  }

  function handleCreateArtis() {
    const urlMicroservice = process.env.REACT_APP_BE_DBTALENT_URL;
    const inputData = {
      id: input.id,
      id_dbtalent: input.id_dbtalent,
      id_ktp: input.id_ktp,
      email_bp: input.email_bp,
      email: input.email,
      telepon_1: input.telepon_1,
      type: "artis_sp",
    };
    axios
      .post(`${urlMicroservice}talent/artis/create`, inputData, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setShowSuccess(true);
      })
      .catch((e) => {
        var message = e.response.data.message;
        if (!message) {
          message = e.message;
        }
        setMessage({
          type: "danger",
          message: JSON.stringify(message),
          show: "d-block",
        });
      });
  }

  function getDataSupplierBYNik() {
    getDataSupplierByNik(
      { nik: input.id_ktp },
      token,
      ({ data, isFetching, isFound, isError }) => {
        if (data) {
          const items = Array.isArray(data.data) ? data.data[0] : null;

          if (items) {
            setInput({
              ...input,
              nama_ktp: items.supplier_name,
              status_pernikahan: items.status_perkawinan,
              agama: items.agama,
              tgl_lahir: items.tanggal_lahir,
              tempat_lahir: items.tempat_lahir,
              jenis_kelamin:
                items.jenis_kelamin == "L"
                  ? "Laki-laki"
                  : items.jenis_kelamin == "P"
                  ? "Perempuan"
                  : "",
              provinsi: items.provinsi,
              kel_desa: items.kelurahan,
              kecamatan: items.kecamatan,
              rt_rw: items.rt_rw,
              pekerjaan: items.pekerjaan,
              kewarganegaraan: items.kewarganegaraan,
              alamat: items.alamat,
              // email_bp: items.email_bp,
              // telepon_1: items.telepon_dbtalent,
            });
            let dataNpwp = [];
            items.data_npwp.forEach((element) => {
              if (!element.npwp) {
                dataNpwp.push({
                  nama_npwp: element.nama,
                  npwp: element.npwp,
                  alamat_npwp: element.alamat,
                });
              }
            });

            setInputNpwp(dataNpwp);
          }
        }
      }
    );
  }

  const handlegetDataNpwp = (noNpwp, key) => {
    getDataNpwp({ no_npwp: noNpwp }, token, ({ data }) => {
      if (data) {
        const datanpwp = {
          npwp: noNpwp,
          nama_npwp: "",
          alamat_npwp: "",
        };

        if (Array.isArray(data.data)) {
          const validData = data.data.find((element) => element.nama !== "");
          if (validData) {
            datanpwp.nama_npwp = validData.nama;
            datanpwp.alamat_npwp = validData.alamat;
          }
        }

        setInputNpwp((prev) =>
          prev.map((item, idx) =>
            idx === key ? { ...item, ...datanpwp } : item
          )
        );
      }
    });
  };

  return (
    <>
      <Button
        variant="outline-info"
        className="w-100 mt-2"
        type="button"
        onClick={handleShow}
      >
        Jadikan Artis Sp
      </Button>

      {/* show warning */}
      <Modal show={showWarning}>
        <Modal.Body>
          <Row className="my-5">
            <Col sm="12" className="text-center">
              <Image
                src={urlImageAvatar}
                roundedCircle
                style={{ height: "100px", width: "100px" }}
              />
            </Col>
            <Col sm="12" className="text-center mt-5">
              <p className="font-weight-bold">{resWarning}</p>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col sm="6" className="text-right">
              <Button
                variant="outline-danger"
                className="px-5"
                onClick={() => {
                  setShowWarning(false);
                  setShow(true);
                }}
              >
                Batal
              </Button>
            </Col>
            <Col sm="6">
              <Button
                variant="danger"
                className="px-5"
                disabled={disabled}
                onClick={submit2}
              >
                Simpan
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Lengkapi Data Kandidat Artis</Modal.Title>
        </Modal.Header>
        <Modal.Body className="my-2">
          <Grid.Row>
            <Grid.Col width={2}>
              <Image src="/images/icon/biodata.png" className="artis-icon" />
              <h4 className="font-weight-bold mt-4">Biodata</h4>
            </Grid.Col>

            <Grid.Col width={12}></Grid.Col>

            <Grid.Col className="mb-4" width={8}>
              <FormTextInput
                name="id_ktp"
                label="Nomor Identitas"
                placeholder="Masukkan Nomor Identitas"
                value={input.id_ktp}
                onChange={handleChange}
              />
            </Grid.Col>

            {input.id_ktp && (
              <Grid.Col md={2} width={2} className="button-get-data">
                <div>
                  <Button
                    variant="outline-danger"
                    onClick={() => getDataSupplierBYNik()}
                  >
                    Get Data
                  </Button>
                </div>
              </Grid.Col>
            )}
            <Grid.Col className="mb-4" width={6}>
              <FormTextInput
                name="nama_ktp"
                disabled
                label="Nama Sesuai Identitas"
                value={input.nama_ktp}
                onChange={handleChange}
              />
            </Grid.Col>

            <Grid.Col className="mb-4" width={6}>
              <FormTextInput
                disabled
                name="status_pernikahan"
                label="Status Pernikahan"
                value={input.status_pernikahan}
                onChange={handleChange}
              />
            </Grid.Col>
            <Grid.Col className="mb-4" width={6}>
              <FormTextInput
                disabled
                name="agama"
                label="Agama"
                value={input.agama}
                onChange={handleChange}
              />
            </Grid.Col>
            <Grid.Col className="mb-4" width={6}>
              <FormTextInput
                disabled
                name="Tanggal Lahir"
                label="Tanggal Lahir"
                value={input.tgl_lahir}
                onChange={handleChange}
              />
            </Grid.Col>

            <Grid.Col className="mb-4" width={6}>
              <FormTextInput
                disabled
                name="tempat_lahir"
                label="Tempat Lahir"
                value={input.tempat_lahir}
                onChange={handleChange}
              />
            </Grid.Col>

            <Grid.Col className="mb-4" width={6}>
              <FormTextInput
                disabled
                name="jenis_kelamin"
                label="Jenis Kelamin"
                value={input.jenis_kelamin}
                onChange={handleChange}
              />
            </Grid.Col>

            <Grid.Col className="mb-4" width={6}>
              <FormTextInput
                disabled
                name="provinsi"
                label="Provinsi"
                value={input.provinsi}
                onChange={handleChange}
              />
            </Grid.Col>

            <Grid.Col className="mb-4" width={6}>
              <FormTextInput
                disabled
                name="kel_desa"
                label="Kel/Desa"
                value={input.kel_desa}
                onChange={handleChange}
              />
            </Grid.Col>

            <Grid.Col className="mb-4" width={6}>
              <FormTextInput
                disabled
                name="kecamatan"
                label="Kecamatan"
                value={input.kecamatan}
                onChange={handleChange}
              />
            </Grid.Col>

            <Grid.Col className="mb-4" width={6}>
              <FormTextInput
                disabled
                name="rt_rw"
                label="RT/RW"
                value={input.rt_rw}
                onChange={handleChange}
              />
            </Grid.Col>

            <Grid.Col className="mb-4" width={6}>
              <FormTextInput
                disabled
                name="pekerjaan"
                label="Pekerjaan"
                value={input.pekerjaan}
                onChange={handleChange}
              />
            </Grid.Col>

            <Grid.Col className="mb-4" width={6}>
              <FormTextInput
                disabled
                name="kewarganegaraan"
                label="Kewarganegaraan"
                value={input.kewarganegaraan}
                onChange={handleChange}
              />
            </Grid.Col>

            <Grid.Col className="mb-4" width={12}>
              <Form.Group label={<Form.Label children="Alamat" />}>
                <Form.Textarea
                  disabled
                  name="alamat"
                  rows={3}
                  value={input.alamat}
                  onChange={handleChange}
                />
              </Form.Group>
            </Grid.Col>
            <Grid.Col className="mb-4" width={6}>
              <FormTextInput
                name="telepon_1"
                label="Telepon "
                placeholder="Masukkan Telepon"
                value={input.telepon_1 || ""}
                onChange={handleChange}
              />
            </Grid.Col>

            <Grid.Col className="mb-4" width={6}>
              <FormTextInput
                name="email"
                label="Email"
                type="email"
                placeholder="Masukkan Email"
                value={input.email}
                onChange={handleChange}
                onBlur={(e) => {
                  if (input.email_bp == "")
                    setInput({
                      ...input,
                      email_bp: e.target.value,
                    });
                }}
              />
            </Grid.Col>

            <Grid.Col className="mb-4" width={12}>
              <FormTextInput
                name="email_bp"
                label="Email BP"
                type="email"
                placeholder="Masukkan Email BP"
                value={input.email_bp}
                onChange={handleChange}
              />
            </Grid.Col>
          </Grid.Row>
          <Grid.Col width={12}>
            <Grid.Col width={2}>
              <Image src="/images/icon/npwp.png" className="artis-icon" />
              <h4 className="font-weight-bold mt-4">NPWP</h4>
            </Grid.Col>
            {inputNpwp.map((data, index) => (
              <Grid.Row
                className="mt-4 align-items-center"
                ey={`${data}~${index}`}
              >
                <Grid.Col className="mb-4" width={8}>
                  <FormTextInput
                    name="npwp"
                    label="NPWP"
                    placeholder="Masukkan NPWP"
                    value={data.npwp}
                    onChange={(event) => handleInputNpwpChange(index, event)}
                  />
                </Grid.Col>
                {data.npwp && (
                  <Grid.Col className="button-get-data" width={2}>
                    <div>
                      <Button
                        variant="outline-danger"
                        onClick={() => handlegetDataNpwp(data.npwp, index)}
                      >
                        Get Data
                      </Button>
                    </div>
                  </Grid.Col>
                )}

                <Grid.Col className="mb-4" width={12}>
                  <FormTextInput
                    name="nama_npwp"
                    label="Nama NPWP"
                    disabled
                    value={data.nama_npwp}
                    onChange={(event) => handleInputNpwpChange(index, event)}
                  />
                </Grid.Col>

                <Grid.Col className="mb-4" width={12}>
                  <Form.Group label={<Form.Label children="Alamat" />}>
                    <Form.Textarea
                      name="alamat_npwp"
                      rows={3}
                      disabled
                      value={data.alamat_npwp}
                      onChange={(event) => handleInputNpwpChange(index, event)}
                    />
                  </Form.Group>
                </Grid.Col>
                {index == 0 ? (
                  ""
                ) : (
                  <Grid.Col className="mb-4" width={1}>
                    <Button
                      variant="none"
                      onClick={() => handleRemoveNpwpFields(index)}
                    >
                      <i className="bx bxs-trash text-danger fa-2x"></i>
                    </Button>
                  </Grid.Col>
                )}
              </Grid.Row>
            ))}
            <div className="mb-3">
              <Button
                variant="outline-danger"
                onClick={() => handleAddNpwpFields()}
              >
                <i className="bx bx-plus"></i> Tambah NPWP
              </Button>
            </div>
          </Grid.Col>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-danger" onClick={handleClose}>
            Batal
          </Button>
          <Button variant="danger" onClick={() => submit()} disabled={disabled}>
            Simpan
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showSuccess} backdrop="static">
        <Modal.Body className="p-5 text-center modal-convert">
          <div className="mb-5 image-circle">
            <Image src={prop.data.foto} />
          </div>
          <Modal.Title className="pt-5">
            {prop.data.nama_artis} Telah Menjadi Artis Sp!
          </Modal.Title>
          <div className="mt-3 mb-5">
            Sekarang Anda dapat menemukannya pada menu Artis Sp
          </div>
          <div className="d-flex justify-content-between">
            <Button
              variant="outline-transparant"
              onClick={() => window.location.reload()}
            >
              Kembali ke Kandidat Artis
            </Button>
            <Link to="/artis-sp">
              <Button variant="light-transparant">Buka Menu Artis Sp</Button>
            </Link>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default JadikanArtisSp;
