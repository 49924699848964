import React from "react";
import { Form, Button, InputGroup, FormControl } from "react-bootstrap";
import { Card } from "tabler-react";

function FilterCard(prop) {
  function handleChange(evt) {
    let value = evt.target.value;
    if (
      evt.target.name === "gender" ||
      evt.target.name === "peran" ||
      evt.target.name === "category_casting"
    ) {
      var oldValue = prop.filter[evt.target.name];
      if (evt.target.checked) {
        oldValue.push(parseInt(value));
      } else {
        var index = oldValue.indexOf(parseInt(value));
        if (index !== -1) {
          oldValue.splice(index, 1);
        }
      }
      value = oldValue;
    } else if (evt.target.name === "eksklusif") {
      var oldValue = prop.filter[evt.target.name];
      if (
        (evt.target.checked && value == "1" && oldValue == "0") ||
        (evt.target.checked && value == "0" && oldValue == "1")
      ) {
        value = "";
      } else if (!evt.target.checked && value == "1") {
        value = "0";
      } else if (!evt.target.checked && value == "0") {
        value = "1";
      }
    } else if (evt.target.name === "available") {
      var oldValue = prop.filter[evt.target.name];
      if (
        (evt.target.checked && value == "1" && oldValue == "0") ||
        (evt.target.checked && value == "0" && oldValue == "1")
      ) {
        value = "";
      } else if (!evt.target.checked && value == "1") {
        value = "0";
      } else if (!evt.target.checked && value == "0") {
        value = "1";
      }
    }
    prop.setFilter({
      ...prop.filter,
      tampil_filter: false,
      filter_id: "",
      [evt.target.name]: value,
    });
  }

  function hapusSemua() {
    prop.setFilter({
      nama_artis: "",
      gender: [1, 2],
      usia_min: "",
      usia_maks: "",
      tinggi_min: "",
      tinggi_maks: "",
      berat_min: "",
      berat_maks: "",
      peran: [],
      nilai_casting_min: "",
      nilai_casting_maks: "",
      talent_prospect: [],
      judul_sinetron: "",
      available: "",
      penerimaan_artis_dari_tanggal: "",
      penerimaan_artis_sampai_tanggal: "",
      filter_id: "",
      tampil_filter: false,
      submit: true,
      eksklusif: "",
      karakter: "",
    });
  }

  const handleClick = () => {
    prop.setFilter({
      ...prop.filter,
      tampil_filter: true,
      submit: true,
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleClick();
    } else if (event.key === "Escape") {
      hapusSemua();
    }
  };

  return (
    <Card className="border-card mt-5">
      <Card.Header className="d-flex justify-content-between">
        <h4 className="mb-0">Filter</h4>
        {/* <Link to={"/kandidat-artis/riwayat-filter"}>
          <Button variant="light">
            <i className="fas fa-history"></i>
          </Button>
        </Link> */}
      </Card.Header>
      <Form>
        <Card.Body>
          <Form.Group className="mb-4" controlId="nama">
            <Form.Label>Nama Artis</Form.Label>
            <Form.Control
              type="text"
              placeholder="Masukkan nama artis"
              name="nama_artis"
              value={prop.filter.nama_artis}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
            />
          </Form.Group>
        </Card.Body>

        <Card.Body>
          <Form.Group className="mb-4" controlId="nama">
            <Form.Label>Gender</Form.Label>
            <Form.Check
              type="checkbox"
              id="male"
              label="Laki-laki"
              className="mb-2 gender"
              name="gender"
              value={1}
              onChange={handleChange}
              checked={prop.filter.gender.includes(1) ? true : false}
              onKeyDown={handleKeyDown}
            />
            <Form.Check
              type="checkbox"
              id="female"
              label="Perempuan"
              className="gender"
              name="gender"
              value={2}
              onChange={handleChange}
              checked={prop.filter.gender.includes(2) ? true : false}
              onKeyDown={handleKeyDown}
            />
          </Form.Group>
        </Card.Body>
        <Card.Footer className="d-flex justify-content-center">
          <Button
            variant="outline-danger"
            className="mr-3 w-100"
            type="button"
            onClick={() => hapusSemua()}
          >
            Reset
          </Button>
          <Button
            variant="danger"
            className="w-100"
            type="button"
            onClick={() =>
              prop.setFilter({
                ...prop.filter,
                tampil_filter: true,
                submit: true,
              })
            }
          >
            Terapkan
          </Button>
        </Card.Footer>
      </Form>
    </Card>
  );
}

export default FilterCard;
