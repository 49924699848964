// @flow

import React, { useContext, useEffect, useState } from "react";
import AlertCustom from "../Components/Modal/AlertCustomComponent";
import { ContextAlert } from "../Context/Alert";
import { ContextPermission } from "../Context/Permission";

// import type { NotificationProps } from "tabler-react";
import LayoutApp from "./LayoutApp";

type Props = {|
  +children: React.Node,
|};

// type State = {|
//   notificationsObjects: Array<NotificationProps>,
// |};

type navItem = {|
  +value: string,
  +to?: string,
  +icon?: string,
|};

function SiteWrapper(props: Props) {
  const lastSegment = location.pathname.split("/").pop();
  const navbar = [
    {
      id: "artis",
      value: "Artis",
      to: "/artis",
      icon: "bxs-id-card",
    },
    {
      id: "kandidat",
      value: "Kandidat Artis",
      to: "/kandidat-artis",
      icon: "bxs-user-circle",
    },
    {
      id: "kandidat_dan_artis",
      value: "ALL Talent",
      to: "/all-talent",
      icon: "bxs-group",
    },
    {
      id: "artis_sp",
      value: "Artis SP",
      to: "/artis-sp",
      icon: "bxs-user",
    },
    {
      id: "bookmark",
      value: "Bookmark",
      to: "/bookmark",
      icon: "bxs-bookmarks",
    },
    {
      id: "judul",
      value: "Judul",
      to: `${
        lastSegment && lastSegment == "judul"
          ? "/judul"
          : lastSegment && lastSegment == "manajemen-group"
          ? "/manajemen-group"
          : "/judul"
      }`,
      icon: "bxs-film",
    },
    // {
    //   id: "user",
    //   value: "Manajemen User",
    //   to: "/manajemen-user",
    //   icon: "bxs-group",
    // },
    // {
    //   id: "role",
    //   value: "Manajemen Role",
    //   to: "/manajemen-role",
    //   icon: "bxs-crown",
    // },
    {
      id: "report",
      value: "Report",
      to: `${
        lastSegment && lastSegment == "grade"
          ? "/report/grade"
          : lastSegment && lastSegment == "prospect"
          ? "/report/prospect"
          : "/report"
      }`,
      icon: "bxs-file",
    },
    {
      id: "crew",
      value: "Master Data",
      to: "/master-data",
      icon: "bxs-data",
    },
  ];

  const statePermission = useContext(ContextPermission);
  const [message, setMessage] = useContext(ContextAlert);

  const [navBarItems, setNavBarItems] = useState([]);

  useEffect(() => {
    var filteredArray = Object.entries(statePermission.home_menu).filter(
      function (item) {
        return item[1].active === 1;
      }
    );

    var filteredNavbar = filteredArray.map((item) => {
      return item[0];
    });

    var newFilteredNavbar = navbar.filter(function (item) {
      return filteredNavbar.includes(item.id);
    });

    setNavBarItems(newFilteredNavbar);
  }, [lastSegment]);

  return (
    <LayoutApp
      // navBarItems={navbar}
      navBarItems={navBarItems}
      breadcrumbItems={props.breadcrumbItems}
      title={props.title}
      rightBreadcrumb={props.rightBreadcrumb}
    >
      <AlertCustom
        setMessage={setMessage}
        showError={message.show}
        message={message.message}
        type={message.type}
      />
      {props.children}
    </LayoutApp>
  );
}

export default SiteWrapper;
